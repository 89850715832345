enum BLOCKS {
  DOCUMENT = "document",
  PARAGRAPH = "paragraph",
  HEADING_1 = "heading-1",
  HEADING_2 = "heading-2",
  HEADING_3 = "heading-3",
  HEADING_4 = "heading-4",
  HEADING_5 = "heading-5",
  HEADING_6 = "heading-6",
  OL_LIST = "ordered-list",
  UL_LIST = "unordered-list",
  LIST_ITEM = "list-item",
  HR = "hr",
  QUOTE = "blockquote",
  EMBEDDED_ENTRY = "embedded-entry-block",
  EMBEDDED_ASSET = "embedded-asset-block",
  TABLE = "table",
  TABLE_ROW = "table-row",
  TABLE_CELL = "table-cell",
  TABLE_HEADER_CELL = "table-header-cell",
}

enum INLINES {
  HYPERLINK = "hyperlink",
  ENTRY_HYPERLINK = "entry-hyperlink",
  ASSET_HYPERLINK = "asset-hyperlink",
  EMBEDDED_ENTRY = "embedded-entry-inline",
}

interface Node {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>;
}

interface Mark {
  type: string;
}

interface Text extends Node {
  type: "text";
  value: string;
  marks: Mark[];
}

interface Inline extends Node {
  type: INLINES;
  content: Array<Inline | Text>;
}

interface Block extends Node {
  type: BLOCKS;
  content: Array<Block | Inline | Text>;
}

const hasValue = (obj: Record<string, unknown>, value: unknown) =>
  Object.values(obj).includes(value);

const isText = (node: Node): node is Text => node.type === "text";

const isBlock = (node: Node): node is Block => hasValue(BLOCKS, node.type);

const isInline = (node: Node): node is Inline => hasValue(INLINES, node.type);

const parseJsonString = (str: string) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
};

export const richTextToString = (data: string, blockDivisor = " "): string => {
  const rootNode = parseJsonString(data);

  if (!rootNode || !rootNode.content) {
    return data;
  }

  return (rootNode as Block).content.reduce((acc: string, node: Node, i: number): string => {
    let nodeTextValue = "";

    if (isText(node)) {
      nodeTextValue = node.value;
    } else if (isBlock(node) || isInline(node)) {
      nodeTextValue = richTextToString(JSON.stringify(node), blockDivisor);
      if (!nodeTextValue.length) {
        return acc;
      }
    }

    const nextNode = rootNode.content[i + 1];
    const isNextNodeBlock = nextNode && isBlock(nextNode);
    const divisor = isNextNodeBlock ? blockDivisor : "";

    return acc + nodeTextValue + divisor;
  }, "");
};
